const settings = {
  BOT_TEXT_MAX_LINES: 20,
  TRAILING_MESSAGES_DISPLAYED: 100,
  maintenance: {
    active: false,
    title: "We're undergoing scheduled maintenance",
    message: 'Please check back soon, just putting the finishing touches on some pretty awesome updates!'
  },
  GA: [
    {
      type: 'event',
      name: 'testing event',
      payload: {
        event_category: 'sample',
        event_label: 'SAMPLE LABEL',
        value: 12
      } //payload depends on type of event being sent.
    }
  ],
  AUTO_RESTART_SESSION: false,
  ENABLE_FEEDBACK: false,
  HIDE_RESTART_ALERT_ON_FEEDBACK_PAGE: false,
  FEEDBACK_FORM_NAME: '',
  IMG_TEMPLATE_SHOW_FULL: false,
  PERSISTENT_MENU_BUTTON: true,
  WELCOME_SCREEN_SETTINGS: {
    display: false,
    welcomeTitle: 'Hello!',
    welcomeMessageMain: 'I am Pand.ai and I make retrieving financial information easy.',
    welcomeMessageSecondary: 'Ask me about anything.',
    actionButton: {
      active: false,
      text: 'Button Label',
      secondaryText: '',
      payload: 'FL5be7a85b4e2da6b76d7f3b64_0'
    }
  },
  WIDE_MODE: true,
  FULLSCREEN_SMALL_WIDGET: false,
  WIDGET_LOCATION: 'right',
  CHAT_OPEN_BY_DEFAULT: false,
  QR_LIMIT: 4,
  QR_SHOW_ALL: true,
  SHOW_USER_FORM_RESPONSE: false,
  SHOW_WATERMARK: false,
  SOCKET_DIALOG: {
    title: 'Session has been disconnected.',
    subTitle: 'Click the button below or refresh the page to restart the session',
    buttonText: 'Restart'
  },
  WEBSHARE_IGNORED_QRS: ['5ece45e8b3c31a04fdf4d36e'], //default webshare payload, to hide when webshare is unavailable
  USE_DYNAMIC_QR_LENGTH: false,
  USE_CUSTOM_LAUNCHER_ICON: false,
  USE_CUSTOM_LAUNCHER: false,
  MINIMIZE_LAUNCHER: false,
  CUSTOM_LAUNCHER_HEIGHT: 200,
  CUSTOM_LAUNCHER_WIDTH: 200,
  LAUNCHER_BUTTON_ID: 'launcherButton',
  USE_SESSION: true,
  ENABLE_FILE_UPLOAD: true,
  HEADER_SHOW_NAME: false,
  HEADER_BADGE_STYLE: 'rectangle',
  HEADER_MENU: false,
  HEADER_MENU_FOLDED: true,
  HEADER_MENU_ITEMS: [],
  NAVIGATION_ITEMS: [  ],

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  //                                           Styling Options                                            //
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  /*
    Available installed fonts:
    - 'Helvetica Neue'
    - 'Raleway, sans-serif'
    - 'Noto Sans, sans-serif'
    - 'Inter, sans-serif'
  */

  BACKGROUND_COLOR: "rgb(128, 128, 128)",
  BUTTON: {   //non hover state button colors follow global color, this is to prevent color clashing
    hoverState: {
      color: '',
      borderColor: '',
      backgroundColor: ''
    }
  },
  BOT_NAME: "GINA Agent",
  BOT_MSG: { //change values to string if u want to change the colors, otherwise leave as null
    color: null,
    backgroundColor: null,
    timeStampColor: null,
    lineHeight: 1.0,
    maxWidth: '80%'
  },
  FONT_FAMILY: 'Raleway, sans-serif',
  GLOBAL_COLOR: "#0096b8",
  HEADER_COLOR: "#0096b8",
  NOTIFICATION_BANNER: {
    color: "#000000",
    backgroundColor: '#fed94bf0',
    button: {
      color: 'white',
      backgroundColor: '#0b5fa8'
    }
  },
  PERSISTENT_MENU: {
    color: '',
    backgroundColor: ''
  },
  SEND_ICON_COLOR: "#000000",
  SIDEBAR: {
    enableEndChatButton: false,
    color: "white",
    borderColor: "white",
    backgroundColor: "transparent",
    logo: {
      width: 100,  // leave as auto unless width is broken. Either a number, 'auto' or '90%' (percentage based value)
      height: 100, // leave as auto unless height is broken. Either a number, 'auto' or '90%' (percentage based value)
      url: 'https://www.allenandgledhill.com/'
    },
  },
  SHARE_BUTTON_BACKGROUND_COLOR: "#2d55b2cf",
  SHARE_ICON_COLOR: "white",
  USER_MESSAGE_COLOR: "white",
  WELCOME_COLOR: "#000000"
}

export default settings